import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DownloaderComponent } from './components/downloader/downloader.component';
import { EnargasComponent } from './components/enargas/enargas.component';
import { InteractionsDetailComponent } from './components/interactions-detail/interactions-detail.component';


const routes: Routes = [
  {
    path: 'download',
    component: DownloaderComponent
  },
  {
    path: 'download/enargas',
    component: EnargasComponent
  },
  {
    path: 'download/interactions-detail',
    component: InteractionsDetailComponent
  },
  {
    path: 'download/field-accumulators',
    component: DownloaderComponent
  },
  {
    path: 'download/event-counter',
    component: DownloaderComponent
  },
  {
    path: 'download/polls',
    component: DownloaderComponent
  },
  {
    path: 'download/records-detail',
    component: DownloaderComponent
  },
  {
    path: 'download/abandoned-interactions-detail',
    component: DownloaderComponent
  },
  {
    path: 'download/number-of-records-by-type',
    component: DownloaderComponent
  },
  {
    path: 'download/agent-states-log-report',
    component: DownloaderComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
