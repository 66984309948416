<div class="form-group">
    <p class="card-text">Selecciona un rango:</p>
    <div class="center">
        <div>
            <mat-form-field>
                <input matInput [ngxMatDatetimePicker]="pickerFrom" placeholder="Desde" [(ngModel)]="myDatePickerFrom">
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker [defaultTime]="[0,0,0]" #pickerFrom [showSeconds]="true">
                </ngx-mat-datetime-picker>
            </mat-form-field>
            <mat-form-field>
                <input matInput [ngxMatDatetimePicker]="pickerTo" placeholder="Hasta" [(ngModel)]="myDatePickerTo"
                    [min]="myDatePickerFrom">
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker [defaultTime]="[0,0,0]" #pickerTo [showSeconds]="true">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
    </div>
</div>
<button [disabled]="!myDatePickerFrom || !myDatePickerTo" id="generate-enargas" type="button"
    class="btn btn-block btn-secondary" (click)="onGenerateEnargas()">Generar</button>