import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import * as platformClient  from 'purecloud-platform-client-v2';
import { InteractionsDetail }  from '../../models/interactions-detail/interactions-detail';
import { Agent }  from '../../models/interactions-detail/agent';
import { PurecloudService }  from '../../services/purecloud/purecloud.service';

export class ParticipantInteraction {
  participant : platformClient.Models.AnalyticsParticipant;
  interactionsDetail : InteractionsDetail;
}

@Injectable({
  providedIn: 'root'
})
export class InteractionsDetailDataService {
  private Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1000
  });

  constructor(private purecloud: PurecloudService) { }

  generateSync(userIds : string[], from: Date, to: Date) : Promise<InteractionsDetail[]> {
    let promise = new Promise<InteractionsDetail[]>((resolve, reject) => {
      var calls : InteractionsDetail[] = [];
      this.downSync(0, userIds, from, to, calls, resolve, reject);
    });
    return promise;
  }

  private downSync(index : number, userIds : string[], from: Date, to: Date, calls : InteractionsDetail[], resolve, reject) : void {
    index++;
    this.purecloud.postAnalyticsConversationsDetailsQueryByUsers(from, to, userIds, index)
    .then((queryResponse) => this.processSync(queryResponse.conversations, index, userIds,  from, to, calls, resolve, reject))
    .catch((response) => reject(response));
  }
  private processSync(conversations : platformClient.Models.AnalyticsConversation[], index : number, queues : string[], from: Date, to: Date, calls : InteractionsDetail[], resolve, reject) {
    if(conversations == undefined || conversations.length == 0) {
      resolve(calls);
    } else {
      conversations.forEach((conversation) => this.processCall(conversation, queues, from, to, calls));

      this.Toast.fire({
        title: 'Interacciones consideradas ' + calls.length + ' de ' + (100*(index - 1) + conversations.length),
        timer: 1000
      });

      this.downSync(index, queues, from, to, calls, resolve, reject);
    }
  }
  private processCall(conversation : platformClient.Models.AnalyticsConversation, queues : string[], from : Date, to : Date, interactionsDetails : InteractionsDetail[]) {
    var interactionsDetail = new InteractionsDetail();
    interactionsDetail.inicio = conversation.conversationStart;
    interactionsDetail.conversationId = conversation.conversationId;
    interactionsDetail.segmento = 0;
    interactionsDetail.tipoContacto = conversation.participants[0].sessions[0].mediaType;
    interactionsDetail.cliente = conversation.participants[0].sessions[0].ani;
    interactionsDetail.dnis = conversation.participants[0].sessions[0].dnis;
    interactionsDetail.sentido = conversation.originatingDirection;
    interactionsDetail.duracion = this.convertStringToDate(conversation.conversationEnd).getTime() - this.convertStringToDate(conversation.conversationStart).getTime();
    interactionsDetail.preview = 0;
    interactionsDetail.dialing = 0;
    interactionsDetail.ringing = 0;
    interactionsDetail.talkingTime = 0;
    interactionsDetail.hold = 0;
    interactionsDetail.acw = 0;
    interactionsDetail.enCola = 0;

    interactionsDetail.equipo = conversation.participants[0].sessions[0].edgeId;
    interactionsDetail.abandonada = 0;
    interactionsDetail.empresas = conversation.divisionIds;

    var participantInteraction = {
      participant: undefined,
      interactionsDetail: interactionsDetail
    }

    conversation.participants.forEach(participant => this.processParticipantInteractionsDetail(participantInteraction, participant, interactionsDetails));
  }
  private processParticipantInteractionsDetail(participantInteraction : ParticipantInteraction, participant : platformClient.Models.AnalyticsParticipant, interactionsDetails : InteractionsDetail[]) {
    participantInteraction.participant = participant;
    this.processParticipant(participantInteraction, interactionsDetails)
  }
  private processParticipant(participantInteraction : ParticipantInteraction, interactionsDetails : InteractionsDetail[]) {
    switch(participantInteraction.participant.purpose) {
      case 'customer':
      case 'external':
        this.processCustom(participantInteraction.participant, participantInteraction.interactionsDetail, interactionsDetails);
        break;
      case 'acd':
        this.processACD(participantInteraction.participant, participantInteraction.interactionsDetail, interactionsDetails);
        break;
      case 'customer':
      case 'external':
      case 'agent':
        this.processAgent(participantInteraction, interactionsDetails);
        break;
    }

    if(participantInteraction.interactionsDetail.segmentoTipo !== participantInteraction.participant.purpose) {
      participantInteraction.interactionsDetail.segmento = participantInteraction.interactionsDetail.segmento + 1;
      participantInteraction.interactionsDetail.segmentoTipo = participantInteraction.participant.purpose;
    }
  }
  private processCustom(participant : platformClient.Models.AnalyticsParticipant, interactionsDetail : InteractionsDetail, interactionsDetails : InteractionsDetail[]) {
    if(interactionsDetail.custom) {
      interactionsDetail.custom = false;
    } else {
      participant.sessions.forEach((session) => this.processSessionAgent(session, interactionsDetail));
    }
  }
  private processACD(participant : platformClient.Models.AnalyticsParticipant, interactionsDetail : InteractionsDetail, interactionsDetails : InteractionsDetail[]) {
    interactionsDetail.campania = participant.participantName;
    participant.sessions.forEach((session) => this.processSessionACD(session, interactionsDetail));
  }
  private processSessionACD(session : platformClient.Models.AnalyticsSession, interactionsDetail : InteractionsDetail) {
    session.segments.forEach((segment) => this.processSegmentACD(segment, interactionsDetail));
  }
  private processSegmentACD(segment : platformClient.Models.AnalyticsConversationSegment, interactionsDetail : InteractionsDetail) {
    interactionsDetail.enCola += this.getTimeBySegment(segment);
  }
  private processAgent(participantInteraction : ParticipantInteraction, interactionsDetails : InteractionsDetail[]) {
    participantInteraction.interactionsDetail.agente = participantInteraction.participant.userId;
    participantInteraction.participant.sessions.forEach((session) => this.processSessionAgent(session, participantInteraction.interactionsDetail));

    if(interactionsDetails.length > 0) {
      var last = interactionsDetails[interactionsDetails.length - 1];
      if(last.conversationId === participantInteraction.interactionsDetail.conversationId) {
        last.abandonada = 0;
      }
    }

    interactionsDetails.push(participantInteraction.interactionsDetail);
    participantInteraction.interactionsDetail = JSON.parse(JSON.stringify(participantInteraction.interactionsDetail));
    participantInteraction.interactionsDetail.agenteCliente = participantInteraction.interactionsDetail.agente;
    participantInteraction.interactionsDetail.agente = '';
    participantInteraction.interactionsDetail.preview = 0;
    participantInteraction.interactionsDetail.dialing = 0;
    participantInteraction.interactionsDetail.ringing = 0;
    participantInteraction.interactionsDetail.talkingTime = 0;
    participantInteraction.interactionsDetail.hold = 0;
    participantInteraction.interactionsDetail.acw = 0;
    participantInteraction.interactionsDetail.enCola = 0;
    participantInteraction.interactionsDetail.abandonada = 0;
    participantInteraction.interactionsDetail.custom = false;
  }
  private processSessionAgent(session : platformClient.Models.AnalyticsSession, interactionsDetail : InteractionsDetail) {
    interactionsDetail.entrante = session.direction;
    session.segments.forEach((segment) => this.processSegmentAgent(segment, interactionsDetail));
  }
  private processSegmentAgent(segment : platformClient.Models.AnalyticsConversationSegment, interactionsDetail : InteractionsDetail) {
    if(segment.properties) {
      segment.properties.forEach((property) => this.processPropertyAgent(property, interactionsDetail));
    }
    switch(segment.segmentType) {
      case 'alert':
        interactionsDetail.ringing += this.getTimeBySegment(segment);
        break;
      case 'hold':
        interactionsDetail.hold += this.getTimeBySegment(segment);
        break;
      case 'wrapup':
        interactionsDetail.acw += this.getTimeBySegment(segment);
        interactionsDetail.resultadoGestion = segment.wrapUpCode;
        break;
      case 'interact':
        interactionsDetail.talkingTime += this.getTimeBySegment(segment);
        break;
      case 'dialing':
        interactionsDetail.dialing += this.getTimeBySegment(segment);
        break;
      default:
        break;
    }
    if(segment.disconnectType) {
      interactionsDetail.origenCorte = segment.disconnectType;
      if(interactionsDetail.talkingTime === 0) {
        interactionsDetail.abandonada = 1;
      }
    }
}
private processPropertyAgent(property : platformClient.Models.AnalyticsProperty, interactionsDetail : InteractionsDetail) {
  if(property.property === 'contrato')
    interactionsDetail.contrato = property.value;
}


private getTimeBySegment(segment : platformClient.Models.AnalyticsConversationSegment) : number {
		return this.getTimeByDate(this.convertStringToDate(segment.segmentStart), this.convertStringToDate(segment.segmentEnd));
	}
	private getTimeByDate(startDate : Date, endDate : Date) : number {
		return endDate.getTime() - startDate.getTime();
	}
	public convertStringToDate(date : string) : Date {
    var year = parseInt(date.substring(0, 4));
    var month = parseInt(date.substring(5, 7)) - 1;
    var day = parseInt(date.substring(8, 10));
    var hours = parseInt(date.substring(11, 13));
    var minutes = parseInt(date.substring(14, 16));
    var seconds = parseInt(date.substring(17, 19));
    var milliseconds = 0;
    if(date.indexOf('.') >= 0)
      milliseconds = parseInt(date.substring(20, date.length - 1));
    
		return new Date(year, month, day, hours, minutes, seconds, milliseconds);
	}
}
