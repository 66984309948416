<div class="form-group">
    <p class="card-text">Seleccione un rango:</p>
    <div class="center">
        <div>
            <mat-form-field>
                <input matInput [ngxMatDatetimePicker]="pickerFrom" placeholder="Desde" [(ngModel)]="myDatePickerFrom">
                <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker [defaultTime]="[0,0,0]" #pickerFrom [showSeconds]="true">
                </ngx-mat-datetime-picker>
            </mat-form-field>
            <mat-form-field>
                <input matInput [ngxMatDatetimePicker]="pickerTo" placeholder="Hasta" [(ngModel)]="myDatePickerTo"
                    [min]="myDatePickerFrom">
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <ngx-mat-datetime-picker [defaultTime]="[0,0,0]" #pickerTo [showSeconds]="true">
                </ngx-mat-datetime-picker>
            </mat-form-field>
        </div>
    </div>
    <p class="card-text">Seleccione agentes:</p>
    <div class="center">
        <select name="agentes-dummy" class="duallistbox-dummy" multiple>
            <option *ngFor="let n of carsDummy" [value]="n.id">{{n.name}}</option>
        </select>
    </div>
    <div class="center">
        <div class="duallistbox-custom">
            <select name="agentes" class="duallistbox" multiple hidden>
                <option *ngFor="let n of allAgents" [value]="n.id">{{n.name}}</option>
            </select>
        </div>
    </div>
    <p class="card-text">Ingrese contrato:</p>
    <mat-form-field class="chip-list">
        <mat-chip-list #chipListContract>
            <mat-chip *ngFor="let contract of selectedContract" (removed)="removeContract(contract)">
                {{contract}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input [matChipInputFor]="chipListContract"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addContract($event)">
        </mat-chip-list>
    </mat-form-field>

    <p class="card-text">Ingrese teléfono:</p>
    <mat-form-field class="chip-list">
        <mat-chip-list #chipListPhone>
            <mat-chip *ngFor="let phone of selectedPhone" (removed)="removePhone(phone)">
                {{phone}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
            <input [matChipInputFor]="chipListPhone"
                [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addPhone($event)">
        </mat-chip-list>
    </mat-form-field>
</div>
<button [disabled]="!myDatePickerFrom || !myDatePickerTo" id="generate-interactions-detail" type="button"
    class="btn btn-block btn-secondary" (click)="onGenerateInteractionsDetail()">Generar</button>

<app-script-hack type="text/javascript">
    <![CDATA[
        duallistboxDummy = $('.duallistbox-dummy').bootstrapDualListbox({
                moveOnSelect: false,
                infoTextEmpty: 'Lista vacia',
                infoText: false,
                filterPlaceHolder: 'Filtrar',
                moveSelectedLabel: 'Mover soleccionado',
                moveAllLabel: 'Mover todos',
                removeSelectedLabel: 'Borrar seleccionado',
                removeAllLabel: 'Borrar todos',
                infoTextFiltered: 'Filtrado {0} de {1}',
                filterTextClear: 'Mostrar todo'
            });
        duallistbox11 = $('.duallistbox');
    ]]>
</app-script-hack>