import { Injectable } from '@angular/core';
import * as platformClient from 'purecloud-platform-client-v2';

class getAuthorizationDivisionsOptions implements platformClient.AuthorizationApi.getAuthorizationDivisionsOptions {
  constructor(public pageSize: number) {
  }
}
class getRoutingQueuesOptions implements platformClient.RoutingApi.getRoutingQueuesOptions {
  constructor(public name: string) {
  }
}
class AsyncConversationQuery implements platformClient.Models.AsyncConversationQuery {
  constructor(public interval: string, public conversationFilters: Array<ConversationDetailQueryFilter>, public segmentFilters: Array<SegmentDetailQueryFilter>) {
  }
}
class ConversationDetailQueryFilter implements platformClient.Models.ConversationDetailQueryFilter {
  constructor(public type: string, public predicates: Array<SegmentDetailQueryPredicate>) {
  }
}
class SegmentDetailQueryFilter implements platformClient.Models.SegmentDetailQueryFilter {
  constructor(public type: string, public predicates: Array<SegmentDetailQueryPredicate>) {
  }
}
class SegmentDetailQueryPredicate implements platformClient.Models.SegmentDetailQueryPredicate {
  constructor(public type: string, public dimension: string, public operator: string, public value: string) {
  }
}
class DetailsJobResultsOptions implements platformClient.ConversationsApi.getAnalyticsConversationsDetailsJobResultsOptions {
  constructor(public pageSize: number, public cursor?: string) {
  }
}
class ConversationQuery implements platformClient.Models.ConversationQuery {
  constructor(public interval: string, public conversationFilters: Array<ConversationDetailQueryFilter>, public segmentFilters: Array<SegmentDetailQueryFilter>, public paging: PagingSpec) {
  }
}
class PagingSpec implements platformClient.Models.PagingSpec {
  constructor(public pageSize: number, public pageNumber: number) {
  }
}
class EvaluationDetailQueryFilter implements platformClient.Models.EvaluationDetailQueryFilter {
  constructor(public type: string, public predicates: Array<EvaluationDetailQueryPredicate>) {
  }
}
class EvaluationDetailQueryPredicate implements platformClient.Models.EvaluationDetailQueryPredicate {
  constructor(public type: string, public dimension: string, public operator: string, public value: string) {
  }
}
class getUsersOptions implements platformClient.UsersApi.getUsersOptions {
  constructor(public pageSize: number, public pageNumber: number) {
  }
}

@Injectable({
  providedIn: 'root'
})
export class PurecloudService {
  private apiConversations: platformClient.ConversationsApi;
  private apiRouting: platformClient.RoutingApi;
  private apiUsers: platformClient.UsersApi;
  private apiAuthorization: platformClient.AuthorizationApi;
  private client: platformClient.ApiClientClass;
  private authData: platformClient.AuthData;

  constructor() {
    this.apiConversations = new platformClient.ConversationsApi();
    this.apiRouting = new platformClient.RoutingApi();
    this.apiUsers = new platformClient.UsersApi;
    this.apiAuthorization = new platformClient.AuthorizationApi;
  }

  login(): void {
    this.client = platformClient.ApiClient.instance;
    this.client.setEnvironment('https://api.mypurecloud.com');
    this.client.loginImplicitGrant('bab8154e-5c3d-4f1f-865a-27da381eb940', window.location.origin + "/download")
      .then((response) => this.thenLogin(response))
      .catch((response) => this.catchLogin(response));
  }
  private thenLogin(response: platformClient.AuthData) {
    this.authData = response;
  }
  private catchLogin(response: any) {
    console.info(response);
  }

  private checkLogin(){
    var now = new Date();
    console.log(now.getTime() + ' ... ' + (this.authData.tokenExpiryTime - 10000));
    if (now.getTime() > this.authData.tokenExpiryTime - 10000) {
      return new Promise((resolve, reject) => {
        reject({ status: '401', code: 'bad.credentials', message: 'Credenciales inválidas.' });
      });
    }
    return undefined;
  }

  async postAnalyticsConversationsDetailsQueryByUsers(from: Date, to: Date, users: string[], index: number): Promise<platformClient.Models.AnalyticsConversationQueryResponse> {
    var promise = this.checkLogin();
    if (promise !== undefined)
      return promise;

    var conversationDetailQueryFilter = new ConversationDetailQueryFilter("or", [new SegmentDetailQueryPredicate("dimension", "conversationEnd", "exists", undefined)]);
    var segmentDetailQueryPredicates: SegmentDetailQueryPredicate[] = [];
    if(users.length == 0)
      segmentDetailQueryPredicates.push(new SegmentDetailQueryPredicate("dimension", "userId", "exists", undefined));
    for (var i = 0; i < users.length; i++) {
      segmentDetailQueryPredicates.push(new SegmentDetailQueryPredicate("dimension", "userId", "matches", users[i]));
    }
    var segmentDetailQueryFilter = new SegmentDetailQueryFilter("or", segmentDetailQueryPredicates);

    return this.checkLogin() || this.apiConversations.postAnalyticsConversationsDetailsQuery(new ConversationQuery(this.convertDateToString(from) + '/' + this.convertDateToString(to), [conversationDetailQueryFilter], [segmentDetailQueryFilter], new PagingSpec(100, index)));
  }

  async postAnalyticsConversationsDetailsQueryByQueues(from: Date, to: Date, queues: string[], index: number): Promise<platformClient.Models.AnalyticsConversationQueryResponse> {
    var promise = this.checkLogin();
    if (promise !== undefined)
      return promise;

    var conversationDetailQueryFilter = new ConversationDetailQueryFilter("or", [new SegmentDetailQueryPredicate("dimension", "originatingDirection", "matches", "inbound")]);
    var segmentDetailQueryPredicates: SegmentDetailQueryPredicate[] = [];
    for (var i = 0; i < queues.length; i++) {
      let name = await this.getQueueName(queues[i]);
      if (name === undefined)
        return new Promise((resolve, reject) => {
          reject({ status: '999', code: 'bad.queue', message: 'No se encontro la QUEUE: ' + queues[i] + '.' });
        });

      segmentDetailQueryPredicates.push(new SegmentDetailQueryPredicate("dimension", "queueId", "matches", name));
    }
    var segmentDetailQueryFilter = new SegmentDetailQueryFilter("or", segmentDetailQueryPredicates);

    return this.checkLogin() || this.apiConversations.postAnalyticsConversationsDetailsQuery(new ConversationQuery(this.convertDateToString(from) + '/' + this.convertDateToString(to), [conversationDetailQueryFilter], [segmentDetailQueryFilter], new PagingSpec(100, index)));
  }

  async postAnalyticsConversationsDetailsJobs(from: Date, to: Date, queues: string[]): Promise<platformClient.Models.AsyncQueryResponse> {
    var promise = this.checkLogin();
    if (promise !== undefined)
      return promise;

    var conversationDetailQueryFilter = new ConversationDetailQueryFilter("or", [new SegmentDetailQueryPredicate("dimension", "originatingDirection", "matches", "inbound")]);
    var segmentDetailQueryPredicates: SegmentDetailQueryPredicate[] = [];
    for (var i = 0; i < queues.length; i++) {
      let name = await this.getQueueName(queues[i]);
      if (name === undefined)
        return new Promise((resolve, reject) => {
          reject({ status: '999', code: 'bad.queue', message: 'No se encontro la QUEUE: ' + queues[i] + '.' });
        });

      segmentDetailQueryPredicates.push(new SegmentDetailQueryPredicate("dimension", "queueId", "matches", name));
    }
    var segmentDetailQueryFilter = new SegmentDetailQueryFilter("or", segmentDetailQueryPredicates);

    console.info('conversationDetailQueryFilter: ', conversationDetailQueryFilter);
    console.info('segmentDetailQueryFilters: ', segmentDetailQueryFilter);

    return this.checkLogin() || this.apiConversations.postAnalyticsConversationsDetailsJobs(new AsyncConversationQuery(this.convertDateToString(from) + '/' + this.convertDateToString(to), [conversationDetailQueryFilter], [segmentDetailQueryFilter]));
  }

  private getQueueName(queue: string): Promise<string> {
    return this.apiRouting.getRoutingQueues(new getRoutingQueuesOptions(queue))
      .then((result) => {
        if (result.entities.length > 0)
          return result.entities[0].id;
        return undefined;
      }).catch((error) => {
        return undefined;
      });
  }

  public getDivisions(): Promise<any> {
    return this.apiAuthorization.getAuthorizationDivisions(new getAuthorizationDivisionsOptions(100))
      .then((result) => {
        var divisions = {};
        result.entities.forEach((division) => divisions[division.id] = division.name);
        return divisions;
      }).catch((error) => {
        return undefined;
      });
  }

  getAnalyticsConversationsDetailsJob(jobId: string): Promise<platformClient.Models.AsyncQueryStatus> {
    return this.checkLogin() || this.apiConversations.getAnalyticsConversationsDetailsJob(jobId);
  }

  getAnalyticsConversationsDetailsJobResults(jobId: string, cursor?: string): Promise<platformClient.Models.AnalyticsConversationAsyncQueryResponse> {
    return this.checkLogin() || this.apiConversations.getAnalyticsConversationsDetailsJobResults(jobId, new DetailsJobResultsOptions(1000, cursor));
  }

  getUsers(): Promise<any|Array<any>> {
    return this.checkLogin() || new Promise<Array<any>>((resolve, reject) => {
      this.getUsersInternal(new getUsersOptions(100, 1), new Array<any>(), resolve, reject);
    });
  }
  private getUsersInternal(opts: getUsersOptions, users: Array<any>, resolve, reject) {
    this.apiUsers.getUsers(opts)
      .then((response) => this.thenUsers(response, users, resolve, reject))
      .catch((response) => this.catchUsers(response, reject));
  }
  private thenUsers(response: platformClient.Models.UserEntityListing, users: Array<any>, resolve, reject) {
    response.entities.forEach((user) => users.push({
      id: user.id,
      name: user.name
    }));
    if(response.pageNumber < response.pageCount) {
      this.getUsersInternal(new getUsersOptions(100, (response.pageNumber + 1)), users, resolve, reject);
    } else {
      resolve(users);
    }
  }
  private catchUsers(response: any, reject) {
    reject(response);
  }

  public convertDateToString(date: Date): string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();
    var hour = '' + date.getHours();
    var minute = '' + date.getMinutes();
    var second = '' + date.getSeconds();
    var msecond = '' + date.getMilliseconds();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    if (hour.length < 2)
      hour = '0' + hour;
    if (minute.length < 2)
      minute = '0' + minute;
    if (second.length < 2)
      second = '0' + second;
    if (msecond.length < 2)
      msecond = '0' + msecond;
    if (msecond.length < 3)
      msecond = '0' + msecond;

    return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + '.' + msecond + 'Z';
  }
}
