  <div class="content-header">
    <div class="container-fluid">
        <div class="row mb-2">
            <div class="col-sm-6">
                <h1 class="m-0 text-dark">Descargas</h1>
            </div>
        </div>
    </div>
</div>

<div class="content">
    <div class="card">
        <div class="card-body">
            <div class="row">
                <div class="col-5 col-sm-2">
                    <div class="nav flex-column nav-tabs h-100" id="vert-tabs-tab" role="tablist" aria-orientation="vertical">
                        <a class="nav-link" id="vert-tabs-tab" data-toggle="pill" role="tab" aria-controls="vert-tabs" aria-selected="true" routerLink="/download/enargas">Enargas</a>
                        <a class="nav-link" id="vert-tabs-tab" data-toggle="pill" role="tab" aria-controls="vert-tabs" aria-selected="false" routerLink="/download/interactions-detail">Detalle de Interacciones</a>
                        <a class="nav-link" id="vert-tabs-tab" data-toggle="pill" role="tab" aria-controls="vert-tabs" aria-selected="false" routerLink="/download/field-accumulators">Acumuladores de Campaña</a>
                        <a class="nav-link" id="vert-tabs-tab" data-toggle="pill" role="tab" aria-controls="vert-tabs" aria-selected="false" routerLink="/download/event-counter">Contador de Eventos</a>
                        <a class="nav-link" id="vert-tabs-tab" data-toggle="pill" role="tab" aria-controls="vert-tabs" aria-selected="false" routerLink="/download/polls">Encuestas</a>
                        <a class="nav-link" id="vert-tabs-tab" data-toggle="pill" role="tab" aria-controls="vert-tabs" aria-selected="false" routerLink="/download/records-detail">Detalle de Registros</a>
                        <a class="nav-link" id="vert-tabs-tab" data-toggle="pill" role="tab" aria-controls="vert-tabs" aria-selected="false" routerLink="/download/abandoned-interactions-detail">Detalle Interacciones Abandonadas</a>
                        <a class="nav-link" id="vert-tabs-tab" data-toggle="pill" role="tab" aria-controls="vert-tabs" aria-selected="false" routerLink="/download/number-of-records-by-type">Cantidad de Registros por Tipo</a>
                        <a class="nav-link" id="vert-tabs-tab" data-toggle="pill" role="tab" aria-controls="vert-tabs" aria-selected="false" routerLink="/download/agent-states-log-report">Reporte Log Estados Agentes</a>
                    </div>
                </div>
                <div class="col-7 col-sm-9">
                    <div class="tab-content" id="vert-tabs-tabContent">
                        <div class="tab-pane text-left fade show active" id="vert-tabs" role="tabpanel" aria-labelledby="vert-tabs-tab">
                          <router-outlet></router-outlet>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>