import { Injectable } from '@angular/core';
import Swal from 'sweetalert2'
import * as platformClient  from 'purecloud-platform-client-v2';
import { Enargas }  from '../../models/enargas/Enargas';
import { PurecloudService }  from '../../services/purecloud/purecloud.service';

@Injectable({
  providedIn: 'root'
})
export class EnargasDataService {
  private Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 1000
  });

  constructor(private purecloud: PurecloudService) {
  }

  generateSync(queues : string[], medias : string[], from: Date, to: Date) : Promise<Enargas[]> {
    let promise = new Promise<Enargas[]>((resolve, reject) => {
      var calls : Enargas[] = [];
      this.downSync(0, queues, medias, from, to, calls, resolve, reject);
    });
    return promise;
  }
  private downSync(index : number, queues : string[], medias : string[], from: Date, to: Date, calls : Enargas[], resolve, reject) : void {
    index++;
    this.purecloud.postAnalyticsConversationsDetailsQueryByQueues(from, to, queues, index)
    .then((queryResponse) => this.processSync(queryResponse.conversations, index, queues, medias, from, to, calls, resolve, reject))
    .catch((response) => reject(response));
  }
  private processSync(conversations : platformClient.Models.AnalyticsConversation[], index : number, queues : string[], medias : string[], from: Date, to: Date, calls : Enargas[], resolve, reject) {
    if(conversations == undefined || conversations.length == 0) {
      resolve(calls);
    } else {
      conversations.forEach((conversation) => this.processCall(conversation, queues, medias, from, to, calls));

      this.Toast.fire({
        title: 'Llamadas consideradas ' + calls.length + ' de ' + (100*(index - 1) + conversations.length),
        timer: 1000
      });

      this.downSync(index, queues, medias, from, to, calls, resolve, reject);
    }
  }

  generateAsync(queues : string[], medias : string[], from: Date, to: Date) : Promise<Enargas[]> {
    let promise = new Promise<Enargas[]>((resolve, reject) => {
      var calls : Enargas[] = [];
      this.downAsync(queues, medias, from, to, calls, resolve, reject);
    });
    return promise;
  }
  private downAsync(queues : string[], medias : string[], from: Date, to: Date, calls : Enargas[], resolve, reject) {
    var info = {
      html: undefined,
      cancel: false
    }

    Swal.fire({
      title: 'Esperando...',
      html: 'Se espera la respuesta de Purecloud <b></b>',
      didOpen: () => this.didOpenSwal(queues, medias, from, to, calls, info, resolve, reject),
      willClose: () => {
        info.cancel = true;
      }
    }).then((result) => {
      console.log(result);
    })    
  }
  private async didOpenSwal(queues : string[], medias : string[], from: Date, to: Date, calls : Enargas[], info, resolve, reject) {
    Swal.showLoading();
    const content = Swal.getContent();
    if (content) {
      const b = content.querySelector('b');
      if (b) {
        b.textContent = '';
        info.html = b;
      }

      this.purecloud.postAnalyticsConversationsDetailsJobs(from, to, queues)
      .then((queryResponse) => this.checkStatusJob(queryResponse.jobId, queues, medias, from, to, calls, 0, info, resolve, reject))
      .catch((response) => reject(response));
    }
  }
  private async checkStatusJob(jobId: string, queues : string[], medias : string[], from : Date, to : Date, calls : Enargas[], retry : number, info, resolve, reject) {
    await this.sleep(1000);
    retry++;
    this.purecloud.getAnalyticsConversationsDetailsJob(jobId)
    .then((statusResponse) => this.validateStatusJob(statusResponse.state, jobId, queues, medias, from, to, calls, retry, info, resolve, reject))
    .catch((response) => reject(response));
  }
  private async validateStatusJob(state: string, jobId: string, queues : string[], medias : string[], from : Date, to : Date, calls : Enargas[], retry : number, info, resolve, reject) {
    if(!info.cancel) {
      console.info('a: ' + retry);
      if(state === 'FULFILLED') {
        this.Toast.fire({
          title: 'Comenzando...',
          timer: 5000
        });

          this.purecloud.getAnalyticsConversationsDetailsJobResults(jobId)
        .then((resultResponse) => this.processJob(resultResponse.cursor, jobId, resultResponse.conversations, 0, queues, medias, from, to, calls, resolve, reject))
        .catch((response) => reject(response));
      } else {
        if (info.html) {
          info.html.textContent = ': ' + retry
        }
        await this.checkStatusJob(jobId, queues, medias, from, to, calls, retry, info, resolve, reject)
      }
    } else {
      reject('Cancelado');
    }
  }
  private processJob(cursor: string, jobId: string, conversations : platformClient.Models.AnalyticsConversation[], index : number, queues : string[], medias : string[], from : Date, to : Date, calls : Enargas[], resolve, reject) {
    index++;
    if(conversations == undefined || conversations.length == 0) {
      resolve(calls);
    } else {
      conversations.forEach((conversation) => this.processCall(conversation, queues, medias, from, to, calls));

      this.Toast.fire({
        title: 'Llamadas consideradas ' + calls.length + ' de ' + (1000*(index - 1) + conversations.length),
        timer: 5000
      });

      if(cursor == undefined) {
        resolve(calls);
      } else {
        this.purecloud.getAnalyticsConversationsDetailsJobResults(jobId, cursor)
        .then((resultResponse) => this.processJob(resultResponse.cursor, jobId, resultResponse.conversations, index, queues, medias, from, to, calls, resolve, reject))
        .catch((response) => reject(response));
      }
    }
  }

  private processCall(conversation : platformClient.Models.AnalyticsConversation, queues : string[], medias : string[], from : Date, to : Date, calls : Enargas[]) {
    var conversationMedia = conversation.participants[0].sessions[0].mediaType.toLocaleLowerCase();
    if(conversation.conversationId === '62e9f0da-fee8-476f-a6d6-9bdd5299b0dd' || conversation.conversationId === 'dbced789-69d1-4cb8-9782-d169a6f7d659')
      console.info(conversation);
    if(medias.indexOf(conversationMedia) >= 0 && conversation.originatingDirection === 'inbound') {
      if(conversation.conversationStart != undefined && conversation.conversationEnd != undefined) {
        var start = this.convertStringToDate(conversation.conversationStart);
        if(start.getTime() >= from.getTime() && start.getTime() < to.getTime()) {
          start.setHours(start.getHours() - 3);
  
          var call = new Enargas();
          call.fecha = this.formatDate(start);
          call.hora = this.formatHour(start);
          call.conversation = conversation.conversationId;
          call.queue = undefined;
          call.demora = 0;
          call.duracion = 0;
          call.numero = undefined,
      
          conversation.participants.forEach((participant) => this.processParticipant(participant, call));
  
          if(queues.indexOf(call.queue) >= 0) {
            if(call.numero != '') {
              call.numero = call.numero.replace(/[^0-9]/g, '');
              call.numero = call.numero.startsWith('54') ? call.numero.substring(2) : call.numero;
              call.numero = '' + parseInt(call.numero);
            }
            if(call.numero.length < 8)
              call.numero = '';
            
            if(call.demora < 0)
              call.demora = 0;
            call.demora = Math.floor(call.demora / 1000);
  
            if(call.duracion < 0)
              call.duracion = 0;
            call.duracion = Math.floor(call.duracion / 1000);
  
            calls.push(call);
          }
        }
      }
    }
  }
  private processParticipant(participant : platformClient.Models.AnalyticsParticipant, call : Enargas) {
    switch(participant.purpose) {
      case 'customer':
      case 'external':
        this.processCustom(participant, call);
        break;
      case 'acd':
        this.processACD(participant, call);
        break;
      case 'agent':
        this.processAgent(participant, call);
        break;
    }
  }
  private processCustom(participant : platformClient.Models.AnalyticsParticipant, call : Enargas) {
    if(call.numero == undefined)
      call.numero = participant.participantName.toLocaleLowerCase() === 'anonymous' ? '' : participant.sessions[0].ani;
  }
  private processACD(participant : platformClient.Models.AnalyticsParticipant, call : Enargas) {
    call.queueCount++;
    if(call.queue == undefined) {
      call.queue = participant.participantName

      participant.sessions.forEach((session) => this.processSessionACD(session, call));
    }
  }
  private processSessionACD(session : platformClient.Models.AnalyticsSession, call : Enargas) {
    session.segments.forEach((segment) => this.processSegmentACD(segment, call));
  }
  private processSegmentACD(segment : platformClient.Models.AnalyticsConversationSegment, call : Enargas) {
    call.demora += this.getTimeBySegment(segment);
  }
  private processAgent(participant : platformClient.Models.AnalyticsParticipant, call : Enargas) {
    participant.sessions.forEach((session) => this.processSessionAgent(session, call));
  }
  private processSessionAgent(session : platformClient.Models.AnalyticsSession, call : Enargas) {
    //session.segments = session.segments.sort((a, b) => this.sortByDate(a, b));
    session.segments.forEach((segment) => this.processSegmentAgent(segment, call));
  }
  private processSegmentAgent(segment : platformClient.Models.AnalyticsConversationSegment, call : Enargas) {
    if(call.queueCount == 1 && !call.corto) {
      switch(segment.segmentType) {
        case 'alert':
          call.demora -= this.getTimeBySegment(segment);
          break;
        case 'wrapup':
          //break;
        case 'interact':
          call.atendido = true;
        default:
                call.duracion += this.getTimeBySegment(segment);
          break;
      }
      call.corto = call.atendido && segment.disconnectType != undefined;
    }
  }

  private sortByDate( a : platformClient.Models.AnalyticsConversationSegment, b : platformClient.Models.AnalyticsConversationSegment) {
    var aStartTime = this.convertStringToDate(a.segmentStart).getTime();
    var aEndTime = this.convertStringToDate(a.segmentEnd).getTime();
    var bStartTime = this.convertStringToDate(b.segmentStart).getTime();
    var bEndTime = this.convertStringToDate(b.segmentEnd).getTime();

    if(a.segmentType === 'interact' && aStartTime === aEndTime)
      return 1;
    if(b.segmentType === 'interact' && bStartTime === bEndTime)
      return -1;
    if (aStartTime === bStartTime && aEndTime < bEndTime){
      return -1;
    }
    if ( aStartTime < bStartTime ){
      return -1;
    }
    if ( aStartTime > bStartTime ){
      return 1;
    }
    return 0;
  }
  private sleep(ms:number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
	private getTimeBySegment(segment : platformClient.Models.AnalyticsConversationSegment) : number {
		return this.getTimeByDate(this.convertStringToDate(segment.segmentStart), this.convertStringToDate(segment.segmentEnd));
	}
	private getTimeByDate(startDate : Date, endDate : Date) : number {
		return endDate.getTime() - startDate.getTime();
	}
	public convertStringToDate(date : string) : Date {
    var year = parseInt(date.substring(0, 4));
    var month = parseInt(date.substring(5, 7)) - 1;
    var day = parseInt(date.substring(8, 10));
    var hours = parseInt(date.substring(11, 13));
    var minutes = parseInt(date.substring(14, 16));
    var seconds = parseInt(date.substring(17, 19));
    var milliseconds = 0;
    if(date.indexOf('.') >= 0)
      milliseconds = parseInt(date.substring(20, date.length - 1));
    
		return new Date(year, month, day, hours, minutes, seconds, milliseconds);
	}
	public convertDateToString(date : Date) : string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();
    var hour = '' + date.getHours();
    var minute = '' + date.getMinutes();
    var second = '' + date.getSeconds();
    var msecond = '' + date.getMilliseconds();
    
    if (month.length < 2) 
      month = '0' + month;
    if (day.length < 2) 
      day = '0' + day;
    if (hour.length < 2) 
      hour = '0' + hour;
    if (minute.length < 2) 
      minute = '0' + minute;
    if (second.length < 2) 
      second = '0' + second;
    if (msecond.length < 2) 
      msecond = '0' + msecond;
    if (msecond.length < 3) 
      msecond = '0' + msecond;
  
		return year + '-' + month + '-' + day + 'T' + hour + ':' + minute + ':' + second + '.' + msecond + 'Z';
	}
  public formatDate(date: Date) : string {
    var year = date.getFullYear();
    var month = '' + (date.getMonth() + 1);
    var day = '' + date.getDate();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
  }
  public formatHour(date: Date) : string {
    var hour = '' + date.getHours();
    var minute = '' + date.getMinutes();
    var second = '' + date.getSeconds();

    if (hour.length < 2) 
      hour = '0' + hour;
    if (minute.length < 2) 
      minute = '0' + minute;
    if (second.length < 2) 
      second = '0' + second;

    return [hour, minute, second].join(':');
  }
}
