import { Component, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core"
import { PurecloudService }  from './services/purecloud/purecloud.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'reports';
  private settingsPrefix ='purecloud';

  constructor(private translate: TranslateService, private purecloud: PurecloudService) {
    this.translate.setDefaultLang('es');
    this.translate.use(this.translate.getBrowserLang());
  }
  ngOnInit(): void {
    this.purecloud.login();
  }
}
